<template>
  <div>
    <b-card class="mb-4">
      <div class="row">
        <input-date
          class="col-12 col-md-3 mb-1"
          :label="$t('RELATORIO.DE')"
          v-model="form.DataInicio"
        />
        <input-date
          class="col-12 col-md-3 mb-1"
          :label="$t('RELATORIO.ATE')"
          v-model="form.DataFim"
        />
        <div class="col-12 mt-2 d-flex align-items-center justify-content-end">
          <b-button variant="secondary" @click="limparFiltro">
            {{ $t("GERAL.LIMPAR") }}
          </b-button>
          <b-button
            variant="primary"
            @click="listarContaGrafica()"
            class="ml-2"
          >
            {{ $t("GERAL.PESQUISAR") }}
          </b-button>
        </div>
      </div>
    </b-card>
    <b-card class="mb-4">
      <b-table
        :sticky-header="true"
        :no-border-collapse="true"
        hover
        head-variant="light"
        :items="items"
        :fields="campos"
        responsive="sm"
        class="table-scroll"
        @sort-changed="sortingChanged"
      >
        <template #cell(numero)="data">
          <div class="numero-titulo">
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(tituloNumeros)="data">
          <div
            class="titulos"
            v-b-tooltip.top
            v-clipboard:copy="data.item.tituloNumeros"
            :title="data.item.tituloNumeros"
            @click="onClickAbrirModal(data.item.tituloNumeros)"
          >
            {{ data.item.tituloNumeros }}
          </div>
        </template>
        <template #cell(nomeCedente)="data">
          <div
            class="nomeEmpresa"
            v-b-tooltip.top
            v-clipboard:copy="data.item.nomeCedente"
            :title="data.item.nomeCedente"
          >
            {{ data.item.nomeCedente }}
          </div>
        </template>
        <template #cell(nomeFantasia)="data">
          <div
            class="nomeEmpresa"
            v-b-tooltip.top
            v-clipboard:copy="data.item.nomeFantasia"
            :title="data.item.nomeFantasia"
          >
            {{ data.item.nomeFantasia }}
          </div>
        </template>
        <template #cell(acoes)="data">
          <div>
            <upload-comprovantes
              :registro="data.item"
              @atualizar="
                () => {
                  listarContaGrafica(paginaAtual, porPagina);
                }
              "
            />
          </div>
        </template>
      </b-table>
    </b-card>

    <hr />
    <div class="d-flex align-items-center justify-content-end h5">
      <span class="mr-2 font-weight-light">{{
        $t("RELATORIO.TOTALDEBITOS")
      }}</span>
      <span class="text-info mr-3">{{ valorTotal }}</span>
    </div>

    <valorem-modal
      ref="modal-titulos"
      :titulo-modal="$t(`TITULOS.TITULOS_ENVOLVIDOS`)"
      somente-ok
      titulo-ok="Fechar"
      @ok="onClickFecharModal"
    >
      <div class="info">
        {{ conteudoModalTitulos }}
      </div>
    </valorem-modal>
  </div>
</template>
  
  <script>
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import helpers from "@/common/utils/helpers";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import Confirmacao from "@/components/confirmacao/Confirmacao";
import RelatorioService from "@/common/services/relatorio/relatorio.service";
import UsuarioService from "@/common/services/usuario/usuario.service";
import { InputDate } from "@/components/inputs";
import moment from "moment";
import { Excel } from "@/common/utils/excel.js";
import UploadComprovantes from "@/components/titulos/UploadComprovantes.vue";
export default {
  name: "RelatorioContaGrafica",
  components: {
    InputDate,
    UploadComprovantes,
  },
  data() {
    return {
      form: {
        DataInicio: "",
        DataFim: "",
        CpfCnpj: "",
        CodigoAgente: "",
        campoOrdenacao: "DataPagamento",
        ascendente: true,
      },
      campos: [
        {
          key: "dtLancamento",
          label: this.$t("RELATORIO.CAMPOS_TABELA.DATA_LANCAMENTO"),
          formatter: (value) => {
            if (value) return helpers.formatarDataBr(value);
          },
          sortable: true,
        },
        {
          key: "nmEmpresa",
          label: this.$t("RELATORIO.CAMPOS_TABELA.NOME_EMPRESA"),
          sortable: true,
        },
        {
          key: "nmEvento",
          label: this.$t("RELATORIO.CAMPOS_TABELA.NOME_EVENTO"),
          sortable: true,
        },
        {
          key: "vlDebito",
          label: this.$t("RELATORIO.CAMPOS_TABELA.VALOR_DEBITO"),
          formatter: (value) => {
            if (value) return helpers.formatarValor(value);
          },
        },
        {
          key: "dsComplemento",
          label: this.$t("RELATORIO.CAMPOS_TABELA.DESCRICAO_COMPLEMENTO"),
        },
        {
          key: "ds_evento",
          label: this.$t("RELATORIO.CAMPOS_TABELA.DESCRICAO_EVENTO"),
        },
        //  {
        //     key: "nm_cedente",
        //     label: this.$t("RELATORIO.CAMPOS_TABELA.NOME_CEDENTE"),
        //     sortable: true,
        //   },
        //   {
        //     key: "nr_cnpj",
        //     label: this.$t("RELATORIO.CAMPOS_TABELA.CNPJ_CEDENTE"),
        //     sortable: true,
        //   },
        //   {
        //     key: "cd_consultor",
        //     label: this.$t("RELATORIO.CAMPOS_TABELA.COD_CONSULTOR"),
        //   },
        //   {
        //     key: "dt_posicao",
        //     label: this.$t("RELATORIO.CAMPOS_TABELA.DATA_POSICAO"),
        //     formatter: (value) => {
        //       if (value) return helpers.formatarDataBr(value);
        //     },
        //     sortable: true,
        //   },
        //   {
        //     key: "cd_empresa",
        //     label: this.$t("RELATORIO.CAMPOS_TABELA.COD_EMPRESA"),
        //   },
        //   {
        //     key: "cd_filial",
        //     label: this.$t("RELATORIO.CAMPOS_TABELA.COD_FILIAL"),
        //   },
        //   {
        //     key: "cd_cedente",
        //     label: this.$t("RELATORIO.CAMPOS_TABELA.COD_CEDENTE"),
        //   },
        //   {
        //     key: "nr_diasLancamento",
        //     label: this.$t("RELATORIO.CAMPOS_TABELA.NUMERO_DIAS_LANCAMENTO"),
        //   },
        //   {
        //     key: "cd_evento",
        //     label: this.$t("RELATORIO.CAMPOS_TABELA.COD_EVENTO"),
        //   },
        // {
        //   key: "fl_lancamentomanual",
        //   label: this.$t("RELATORIO.CAMPOS_TABELA.LANCAMENTO_MANUAL"),
        //   sortable: true,
        // },
        // {
        //   key: "vl_credito",
        //   label: this.$t("RELATORIO.CAMPOS_TABELA.VALOR_CREDITO"),
        //   formatter: (value) => {
        //     if (value) return helpers.formatarValor(value);
        //   },
        // },
        // {
        //   key: "vl_saldo",
        //   label: this.$t("RELATORIO.CAMPOS_TABELA.VALOR_SALDO"),
        //   formatter: (value) => {
        //     if (value) return helpers.formatarValor(value);
        //   },
        // },
        // {
        //   key: "vl_encargos",
        //   label: this.$t("RELATORIO.CAMPOS_TABELA.VALOR_ENCARGO"),
        //   formatter: (value) => {
        //     if (value) return helpers.formatarValor(value);
        //   },
        // },
        // {
        //   key: "dt_atualizacao",
        //   label: this.$t("RELATORIO.CAMPOS_TABELA.DATA_ATUALIZACAO"),
        //   formatter: (value) => {
        //     if (value) return helpers.formatarDataBr(value);
        //   },
        //   sortable: true,
        // },
        // {
        //   key: "acoes",
        //   label: this.$t("GERAL.ACOES"),
        // },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [
        5,
        10,
        15,
        {
          value: 100,
          text: this.$t("GERAL.PAGINACAO"),
        },
      ],
      valorTotal: 0,
      valorTotalAtualizado: 0,
      tempoAcesso: "",
      validField: [],
      cedenteOptions: [],
      titulosOptions: [],
      empresaOptions: [],
      porPagina: 0,
      paginaAtual: 0,
      conteudoModalTitulos: "",
    };
  },
  async mounted() {
    let data = moment();

    this.form.DataFim = data.add("-1", "days").format("YYYY-MM-DD");
    this.form.DataInicio = data.add("-1", "days").format("YYYY-MM-DD");
    this.form.CodigoAgente = localStorage.getItem("usuario_codigo_agente");
    this.form.CpfCnpj = helpers.getCnpjFromClaim();

    this.$store.dispatch(DEFINIR_BREADCRUMB, [{ titulo: "Relatórios" }]);
    await this.getUsuarios();
    this.listarContaGrafica();
  },
  computed: {
    temFiltroCedente() {
      return helpers.validarAcesso("FiltroTodosCedentesProgramacao").visualizar;
    },
  },
  methods: {
    baixarExcel: function () {
      this.$store.dispatch(START_LOADING);
      const { cpfCnpjCedente, empresaValue, titulosValue, ...params } =
        this.form;

      if (empresaValue) {
        params.nomeFantasia = empresaValue;
      }

      if (cpfCnpjCedente) params.cpfCnpjCedente = cpfCnpjCedente;
      else delete params.cpfCnpjCedente;

      if (!this.temFiltroCedente)
        params.cpfCnpjCedente = helpers.getCnpjFromClaim();

      if (titulosValue?.length > 0) {
        params.titulos = titulosValue;
      }
      RelatorioService.baixarExcel(params)
        .then((res) => {
          let htmlString = res.data.data.html;
          let doc = new DOMParser().parseFromString(htmlString, "text/html");
          const ex = new Excel();

          for (let sheet of doc.getElementsByClassName("sheet")) {
            ex.adicionarSheet(sheet, sheet.getAttribute("nome-sheet"));
          }

          ex.baixarExcel("Relatório.xlsx");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async getUsuarios() {
      this.$store.dispatch(START_LOADING);

      const params = {
        TamanhoDaPagina: 99999,
        NumeroDaPagina: 0,
      };
      await UsuarioService.listar(params)
        .then((result) => {
          this.cedenteOptions = result.data.data.itens.map((n) => ({
            value: n.cnpj,
            text: n.nomeEmpresa,
          }));
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    sortingChanged(context) {
      this.items = [];
      this.form.campoOrdenacao = helpers.primeiraLetraMaiuscula(context.sortBy);
      this.form.ascendente = !context.sortDesc;
      this.listarContaGrafica();
    },
    limparFiltro() {
      this.form = {
        DataInicio: "",
        DataFim: "",
        CodigoAgente: "",
        CpfCnpj: "",
        campoOrdenacao: "DataPagamento",
        ascendente: true,
      };
      this.listarContaGrafica();
    },
    customLabel({ text }) {
      return text;
    },
    onClickAbrirModal(conteudoModal) {
      this.conteudoModalTitulos = conteudoModal;
      this.$refs["modal-titulos"].abrirModal();
    },
    onClickFecharModal() {
      this.$refs["modal-titulos"].fecharModal();
    },
    setPage(e) {
      this.listarContaGrafica(e, undefined);
      this.currentPage = e;
    },
    setPorPagina(e) {
      this.listarContaGrafica(undefined, e);
      this.perPage = e;
    },
    listarContaGrafica(numeroPagina, porPagina) {
      this.$store.dispatch(START_LOADING);

      const { cpfCnpjCedente, empresaValue, titulosValue, ...params } =
        this.form;

      if (empresaValue) {
        params.nomeFantasia = empresaValue;
      }

      if (cpfCnpjCedente) params.cpfCnpjCedente = cpfCnpjCedente;
      else delete params.cpfCnpjCedente;

      if (!this.temFiltroCedente)
        params.cpfCnpjCedente = helpers.getCnpjFromClaim();

      if (titulosValue?.length > 0) {
        params.titulos = titulosValue;
      }

      RelatorioService.obterContaGrafica({
        ...params,
        NumeroDaPagina: (numeroPagina || this.currentPage) - 1,
        TamanhoDaPagina: porPagina || this.perPage,
      })
        .then((result) => {
          this.valorTotal = helpers.formatarValor(result.data.data.total);
          this.porPagina = porPagina;
          this.paginaAtual = numeroPagina;
          this.items = result.data.data.itens;
          //this.totalRows = result.data.data.paginacao.totalDeElementos;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    atualizarStatus(id, status) {
      this.$store.dispatch(START_LOADING);

      RelatorioService.atualizarStatus({
        id,
        status,
      })
        .then(() => {
          this.listarContaGrafica();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    removerProgramacao(item) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          this.$store.dispatch(START_LOADING);
          RelatorioService.removerProgramacao(item.id)
            .then(() => {
              this.listarContaGrafica();
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
    getClassButton(item) {
      if (item.status === "Programado") return "botao-icone";

      return "botao-icone-disabled";
    },
    compareTitulos(left, right) {
      return (
        Number(left?.value?.replaceAll("-", "")) -
        Number(right?.value?.replaceAll("-", ""))
      );
    },
    compareSacados(left, right) {
      if (left === right) return 0;
      return left > right ? 1 : -1;
    },
  },
  watch: {
    items: {
      handler(items) {
        if (!this.form.empresaValue) {
          this.empresaOptions = [
            ...new Set(items.map((item) => item.nomeFantasia)),
          ]
            .concat([])
            .map((n) => ({ value: n, text: n }));
        }
        if (this.form.titulosValue.length === 0) {
          this.titulosOptions = [
            ...new Set(items.map((item) => item.titulos).flat()),
          ]
            .map((n) => ({
              value: n.numero,
              text: n.numero,
            }))
            .sort(this.compareTitulos);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
  <style lang="scss">
.div-card-titulos {
  display: flex;
  width: 100%;
}

.card-titulos {
  margin: 5px;
  min-height: 400px;
}

.card-conta {
  width: 30%;
  height: 540px;
}

.status-titulo {
  width: 150px;
}

.icone-titulo {
  color: #636970;
}

.numero-titulo {
  width: 60px;
}

.titulos {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nomeEmpresa {
  white-space: nowrap;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.botao-icone:hover {
  transform: scale(1.2);
}

.icones-titulos {
  display: flex;
  align-items: center;
}

.sem-info {
  text-align: center;
  font-weight: 600;
  color: #b1b4b8;
  width: 70%;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.campo-info {
  margin-top: 10px;
}

.botao-icone {
  display: flex;
  align-items: center;
  justify-self: center;
  border: 1px solid #e5e9eb;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  transition: 0.5s;
  cursor: pointer;
}

.botao-icone-disabled {
  @extend .botao-icone;
  opacity: 0.6;
  pointer-events: none;
}

.button-filter {
  margin: 3px;
  cursor: pointer;
}

.botao-filtro {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 29px;
}
</style>
  