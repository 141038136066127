<template>
  <div>
    <b-tabs v-model="tabIndex" content-class="mt-3 tab-autenticacao">
      <b-tab
        class="tabs-autenticao"
        active
        :title="$t('RELATORIO.OPERACAO')"
        :title-link-class="linkClass(0)"
      >
        <RelatorioOperacao v-if="!comercial" />
        <RelatorioOperacaoComercial v-if="comercial" />
      </b-tab>

      <b-tab
        class="tabs-autenticao"
        :title="$t('RELATORIO.ABERTOLIQUIDADO')"
        :title-link-class="linkClass(1)"
      >
        <RelatorioAbertoLiquidado v-if="!comercial" />
        <RelatorioAbertoLiquidadoComercial v-if="comercial" />
      </b-tab>
      <b-tab
        class="tabs-autenticao"
        :title="$t('RELATORIO.RELATORIOPROGRAMACAOPAGAMENTO')"
        :title-link-class="linkClass(2)"
      >
        <RelatorioProgramacaoPagamento v-if="!comercial" />
        <RelatorioProgramacaoPagamentoComercial v-if="comercial" />
      </b-tab>
      <b-tab
        v-if="!comercial"
        class="tabs-autenticao"
        :title="$t('RELATORIO.RELATORIOCONTAGRAFICA')"
        :title-link-class="linkClass(3)"
      >
        <RelatorioContaGrafica />
      </b-tab>
      <b-tab
        v-if="exibir"
        class="tabs-autenticao"
        :title="$t('RELATORIO.RELATORIORECOMPRA')"
        :title-link-class="linkClass(4)"
      >
        <RelatorioRecompra />
      </b-tab>
    </b-tabs>
  </div>
</template>
  
  <script>
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import RelatorioOperacao from "./RelatorioOperacao/RelatorioOperacao.vue";
import RelatorioOperacaoComercial from "./RelatorioOperacao/RelatorioOperacaoComercial.vue";
import RelatorioAbertoLiquidado from "./RelatorioAbertoLiquidado/RelatorioAbertoLiquidado.vue";
import RelatorioAbertoLiquidadoComercial from "./RelatorioAbertoLiquidado/RelatorioAbertoLiquidadoComercial.vue";
import RelatorioProgramacaoPagamento from "./RelatorioProgramacaoPagamento/RelatorioProgramacaoPagamento.vue";
import RelatorioProgramacaoPagamentoComercial from "./RelatorioProgramacaoPagamento/RelatorioProgramacaoPagamentoComercial.vue";
import RelatorioContaGrafica from "./RelatorioContaGrafica/RelatorioContaGrafica.vue";
import RelatorioRecompra from "./RelatorioRecompra/RelatorioRecompra.vue";

export default {
  name: "RelatorioTabs",
  components: {
    RelatorioOperacao,
    RelatorioAbertoLiquidado,
    RelatorioProgramacaoPagamento,
    RelatorioContaGrafica,
    RelatorioRecompra,
    RelatorioOperacaoComercial,
    RelatorioAbertoLiquidadoComercial,
    RelatorioProgramacaoPagamentoComercial,
  },
  data() {
    return {
      tabIndex: 0,
      comercial: false,
      exibir: false,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.RELATORIO") },
      { titulo: this.$t("RELATORIO.LISTAR_RELATORIOS") },
    ]);
    this.comercial = localStorage.getItem("usuario_comercial");
    this.exibir = false;
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["tabs-autenticacao", "active-tab"];
      } else {
        return ["tabs-autenticacao", "inactive-tab"];
      }
    },
  },
};
</script>
  
<style lang="scss">
.nav-link {
  padding: 0.8rem 3rem;
  margin-right: 0.5rem;
}
.tab-autenticacao {
  min-height: 500px;
}
.active-tab {
  background-color: #a08c6a !important;
  color: white !important;
}

.inactive-tab {
  background-color: white !important;
}
</style>
  