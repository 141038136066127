import { render, staticRenderFns } from "./RelatorioRecompra.vue?vue&type=template&id=09993e59&scoped=true&"
import script from "./RelatorioRecompra.vue?vue&type=script&lang=js&"
export * from "./RelatorioRecompra.vue?vue&type=script&lang=js&"
import style0 from "./RelatorioRecompra.vue?vue&type=style&index=0&id=09993e59&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09993e59",
  null
  
)

export default component.exports