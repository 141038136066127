<template>
  <div>
    <b-card class="mb-4">
      <div class="row">
        <input-select-tag
          v-if="temFiltroCedente"
          unico
          class="col-12 col-md-6 mb-1"
          label="CPF ou CNPJ do Cedente"
          :options="cedenteOptions"
          v-model="form.cpfCnpjCedente"
        />
        <input-date
          class="col-12 col-md-2 mb-1"
          :label="$t('AUDITORIA.DE')"
          v-model="form.DataVencimentoDe"
        />
        <input-date
          class="col-12 col-md-2 mb-1"
          :label="$t('AUDITORIA.ATE')"
          v-model="form.DataVencimentoAte"
        />
        <input-select
          class="col-12 col-md-2 mb-1"
          :options="statusTitulo"
          :label="$t('SACADO.STATUS')"
          v-model="form.Status"
        />

        <input-select-tag
          class="col-12 col-md-6 mb-1"
          :label="$t(`SACADO.TITULO`)"
          :options="titulosOptions"
          v-model="form.titulosValue"
        />
        <input-select-tag
          unico
          class="col-12 col-md-6 mb-1"
          :label="$t(`SACADO.EMPRESA`)"
          :options="empresaOptions"
          v-model="form.empresaValue"
        />

        <div class="col-12 mt-2 d-flex align-items-center justify-content-end">
          <b-button variant="secondary" @click="limparFiltro">
            {{ $t("GERAL.LIMPAR") }}
          </b-button>
          <b-button
            @click="baixarExcel()"
            variant="primary"
            class="ml-2"
            v-b-tooltip.hover
            title="Exportar relatório para PDF"
          >
            <div class="d-flex align-items-center">
              <i class="fas fa-file-excel mr-2"></i>
              <span>Exportar para excel</span>
            </div>
          </b-button>
          <b-button variant="primary" @click="listarPagamentos()" class="ml-2">
            {{ $t("GERAL.PESQUISAR") }}
          </b-button>
        </div>
      </div>
    </b-card>
    <b-card class="mb-4">
      <b-table
        :sticky-header="true"
        :no-border-collapse="true"
        hover
        head-variant="light"
        :items="items"
        :fields="campos"
        responsive="sm"
        class="table-scroll"
        @sort-changed="sortingChanged"
      >
        <template #cell(numero)="data">
          <div class="numero-titulo">
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(tituloNumeros)="data">
          <div
            class="titulos"
            v-b-tooltip.top
            v-clipboard:copy="data.item.tituloNumeros"
            :title="data.item.tituloNumeros"
            @click="onClickAbrirModal(data.item.tituloNumeros)"
          >
            {{ data.item.tituloNumeros }}
          </div>
        </template>
        <template #cell(nomeCedente)="data">
          <div
            class="nomeEmpresa"
            v-b-tooltip.top
            v-clipboard:copy="data.item.nomeCedente"
            :title="data.item.nomeCedente"
          >
            {{ data.item.nomeCedente }}
          </div>
        </template>
        <!-- <template #cell(sacados)="data">
				<div
				class="nomeEmpresa"
				v-b-tooltip.top
				v-clipboard:copy="data.item.sacados"
				:title="data.item.sacados"
				>
				{{ data.item.sacados }}
				</div>
			</template> -->
        <template #cell(nomeFantasia)="data">
          <div
            class="nomeEmpresa"
            v-b-tooltip.top
            v-clipboard:copy="data.item.nomeFantasia"
            :title="data.item.nomeFantasia"
          >
            {{ data.item.nomeFantasia }}
          </div>
        </template>
        <template #cell(status)="data">
          <div v-if="data.item.status !== 'Programado'">
            {{ data.item.status }}
          </div>
          <input-select
            v-if="data.item.status === 'Programado'"
            :options="statusTitulo"
            v-model="data.item.status"
            placeholder="Tipo"
            class="status-titulo"
            @input="(e) => atualizarStatus(data.item.id, e)"
          ></input-select>
        </template>

        <template #cell(acoes)="data">
          <div>
            <upload-comprovantes
              :registro="data.item"
              @atualizar="
                () => {
                  listarPagamentos(paginaAtual, porPagina);
                }
              "
            />
          </div>
        </template>
      </b-table>
    </b-card>
    <div class="header-table-operacoes">
      <b-form-group
        label="Por Pagina"
        label-for="per-page-select"
        label-class="fw-medium"
        class="por-pagina"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          @change="setPorPagina"
        ></b-form-select>
      </b-form-group>
      <b-pagination
        @change="setPage"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      ></b-pagination>
    </div>

    <valorem-modal
      ref="modal-titulos"
      :titulo-modal="$t(`TITULOS.TITULOS_ENVOLVIDOS`)"
      somente-ok
      titulo-ok="Fechar"
      @ok="onClickFecharModal"
    >
      <div class="info">
        {{ conteudoModalTitulos }}
      </div>
    </valorem-modal>
  </div>
</template>

<script>
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import helpers from "@/common/utils/helpers";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import InputSelect from "@/components/inputs/InputSelect";
import Confirmacao from "@/components/confirmacao/Confirmacao";
import RelatorioService from "@/common/services/relatorio/relatorio.service";
import UsuarioService from "@/common/services/usuario/usuario.service";
import { InputDate, InputSelectTag } from "@/components/inputs";
import moment from "moment";
import { Excel } from "@/common/utils/excel.js";
import UploadComprovantes from "@/components/titulos/UploadComprovantes.vue";
export default {
  name: "RelatorioProgramacaoPagamento",
  components: {
    InputSelect,
    InputDate,
    InputSelectTag,
    UploadComprovantes,
  },
  data() {
    return {
      form: {
        cpfCnpjCedente: "",
        empresaValue: "",
        // sacadosValue: [],
        titulosValue: [],
        campoOrdenacao: "DataPagamento",
        ascendente: true,
      },
      statusTitulo: [
        {
          value: "Programado",
          text: "Programado",
        },
        {
          value: "Baixado",
          text: "Baixado",
        },
        {
          value: "Cancelado",
          text: "Cancelado",
        },
      ],
      campos: [
        {
          key: "dataPagamento",
          label: this.$t("CAMPOS_TABELA_TITULOS.DATA"),
          formatter: (value) => {
            if (value) return helpers.formatarDataBr(value);
          },
          sortable: true,
        },
        {
          key: "nomeCedente",
          label: this.$t("SACADO.CEDENTE"),
          sortable: true,
        },
        // {
        //   key: 'sacados',
        //   label: this.$t('SACADO.SACADO'),
        //   sortable: true,
        // },
        {
          key: "tituloNumeros",
          label: this.$t("TITULOS.TITULOS_ENVOLVIDOS"),
          sortable: true,
        },
        {
          key: "nomeFantasia",
          label: this.$t("SACADO.EMPRESA"),
          sortable: true,
        },
        {
          key: "valorTotalAtualizado",
          label: this.$t("SACADO.VALOR_TOTAL"),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatarValor(value);
          },
        },
        {
          key: "status",
          label: this.$t("SACADO.STATUS"),
          sortable: true,
        },
        {
          key: "acoes",
          label: this.$t("GERAL.ACOES"),
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [
        5,
        10,
        15,
        {
          value: 100,
          text: this.$t("GERAL.PAGINACAO"),
        },
      ],
      valorTotal: 0,
      valorTotalAtualizado: 0,
      tempoAcesso: "",
      validField: [],
      // sacadoOptions: [],
      cedenteOptions: [],
      titulosOptions: [],
      empresaOptions: [],
      porPagina: 0,
      paginaAtual: 0,
      conteudoModalTitulos: "",
    };
  },
  async mounted() {
    let data = moment();

    this.form.DataVencimentoAte = data.add("-1", "days").format("YYYY-MM-DD");
    this.form.DataVencimentoDe = data.add("-1", "days").format("YYYY-MM-DD");

    this.$store.dispatch(DEFINIR_BREADCRUMB, [{ titulo: "Relatórios" }]);

    if (this.temFiltroCedente) {
      this.getUsuarios();
    }

    this.listarPagamentos();
  },
  computed: {
    temFiltroCedente() {
      return helpers.validarAcesso("FiltroTodosCedentesProgramacao").visualizar;
    },
  },
  methods: {
    baixarExcel: function () {
      this.$store.dispatch(START_LOADING);
      const {
        cpfCnpjCedente,
        empresaValue,
        // sacadosValue,
        titulosValue,
        ...params
      } = this.form;

      if (empresaValue) {
        params.nomeFantasia = empresaValue;
      }

      if (cpfCnpjCedente) params.cpfCnpjCedente = cpfCnpjCedente;
      else delete params.cpfCnpjCedente;

      if (!this.temFiltroCedente)
        params.cpfCnpjCedente = helpers.getCnpjFromClaim();

      if (titulosValue?.length > 0) {
        params.titulos = titulosValue;
      }
      RelatorioService.baixarExcel(params)
        .then((res) => {
          let htmlString = res.data.data.html;
          let doc = new DOMParser().parseFromString(htmlString, "text/html");
          const ex = new Excel();

          for (let sheet of doc.getElementsByClassName("sheet")) {
            ex.adicionarSheet(sheet, sheet.getAttribute("nome-sheet"));
          }

          ex.baixarExcel("Relatório.xlsx");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async getUsuarios() {
      this.$store.dispatch(START_LOADING);

      const params = {
        TamanhoDaPagina: 99999,
        NumeroDaPagina: 0,
      };
      await UsuarioService.listar(params)
        .then((result) => {
          this.cedenteOptions = result.data.data.itens.map((n) => ({
            value: n.cnpj,
            text: n.nomeEmpresa,
          }));
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    sortingChanged(context) {
      this.items = [];
      this.form.campoOrdenacao = helpers.primeiraLetraMaiuscula(context.sortBy);
      this.form.ascendente = !context.sortDesc;
      this.listarPagamentos();
    },
    limparFiltro() {
      this.form = {
        cpfCnpjCedente: "",
        empresaValue: "",
        // sacadosValue: [],
        titulosValue: [],
        campoOrdenacao: "DataPagamento",
        ascendente: true,
      };
      this.listarPagamentos();
    },
    customLabel({ text }) {
      return text;
    },
    // onChangeSacados(value) {
    //   this.form.sacadosValue = value;
    // },
    onClickAbrirModal(conteudoModal) {
      this.conteudoModalTitulos = conteudoModal;
      this.$refs["modal-titulos"].abrirModal();
      // alert(conteudoModal);
    },
    onClickFecharModal() {
      this.$refs["modal-titulos"].fecharModal();
    },
    onChangeTitulos(value) {
      this.form.titulosValue = value;
    },
    onChangeCedente(value) {
      this.form.cpfCnpjCedente = value;
    },
    onChangeEmpresa(value) {
      this.form.empresaValue = value;
    },
    setPage(e) {
      this.listarPagamentos(e, undefined);
      this.currentPage = e;
    },
    setPorPagina(e) {
      this.listarPagamentos(undefined, e);
      this.perPage = e;
    },
    listarPagamentos(numeroPagina, porPagina) {
      this.$store.dispatch(START_LOADING);

      const {
        cpfCnpjCedente,
        empresaValue,
        // sacadosValue,
        titulosValue,
        ...params
      } = this.form;

      if (empresaValue) {
        params.nomeFantasia = empresaValue;
      }

      if (cpfCnpjCedente) params.cpfCnpjCedente = cpfCnpjCedente;
      else delete params.cpfCnpjCedente;

      if (!this.temFiltroCedente)
        params.cpfCnpjCedente = helpers.getCnpjFromClaim();

      if (titulosValue?.length > 0) {
        params.titulos = titulosValue;
      }
      // if (sacadosValue?.length > 0) {
      //   params.sacados = sacadosValue.map((s) => s.value);
      // }

      RelatorioService.buscarProgramacao({
        ...params,
        NumeroDaPagina: (numeroPagina || this.currentPage) - 1,
        TamanhoDaPagina: porPagina || this.perPage,
      })
        .then((result) => {
          this.porPagina = porPagina;
          this.paginaAtual = numeroPagina;
          this.items = result.data.data.itens;
          this.totalRows = result.data.data.paginacao.totalDeElementos;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    atualizarStatus(id, status) {
      this.$store.dispatch(START_LOADING);

      RelatorioService.atualizarStatus({
        id,
        status,
      })
        .then(() => {
          this.listarPagamentos();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    removerProgramacao(item) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          this.$store.dispatch(START_LOADING);
          RelatorioService.removerProgramacao(item.id)
            .then(() => {
              this.listarPagamentos();
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
    getClassButton(item) {
      if (item.status === "Programado") return "botao-icone";

      return "botao-icone-disabled";
    },
    compareTitulos(left, right) {
      return (
        Number(left?.value?.replaceAll("-", "")) -
        Number(right?.value?.replaceAll("-", ""))
      );
    },
    compareSacados(left, right) {
      if (left === right) return 0;
      return left > right ? 1 : -1;
    },
  },
  watch: {
    items: {
      handler(items) {
        // if (this.form.sacadosValue.length === 0) {
        //   const sacados = items
        //     .map((item) => item.titulos)
        //     .flat()
        //     .map((titulo) => titulo.nomeSacado);
        //   sacados.sort(this.compareSacados);
        //   this.sacadoOptions = [...new Set(sacados)].map((n) => ({
        //     value: n,
        //     text: n,
        //   }));
        // }

        if (!this.form.empresaValue) {
          this.empresaOptions = [
            ...new Set(items.map((item) => item.nomeFantasia)),
          ]
            .concat([])
            .map((n) => ({ value: n, text: n }));
        }
        if (this.form.titulosValue.length === 0) {
          this.titulosOptions = [
            ...new Set(items.map((item) => item.titulos).flat()),
          ]
            .map((n) => ({
              value: n.numero,
              text: n.numero,
            }))
            .sort(this.compareTitulos);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.div-card-titulos {
  display: flex;
  width: 100%;
}

.card-titulos {
  margin: 5px;
  min-height: 400px;
}

.card-conta {
  width: 30%;
  height: 540px;
}

.status-titulo {
  width: 150px;
}

.icone-titulo {
  color: #636970;
}

.numero-titulo {
  width: 60px;
}

.titulos {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nomeEmpresa {
  white-space: nowrap;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.botao-icone:hover {
  transform: scale(1.2);
}

.icones-titulos {
  display: flex;
  align-items: center;
}

.sem-info {
  text-align: center;
  font-weight: 600;
  color: #b1b4b8;
  width: 70%;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.campo-info {
  margin-top: 10px;
}

.botao-icone {
  display: flex;
  align-items: center;
  justify-self: center;
  border: 1px solid #e5e9eb;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  transition: 0.5s;
  cursor: pointer;
}

.botao-icone-disabled {
  @extend .botao-icone;
  opacity: 0.6;
  pointer-events: none;
}

.button-filter {
  margin: 3px;
  cursor: pointer;
}

.botao-filtro {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 29px;
}
</style>
